import { React, CenterWrapper, View, Text, SEO, Page, Button } from 'lib'
import { Theme, HTMLStylesDark, license, timestamp } from 'app'
import moment from 'moment'
import { PageTitle } from 'components'
import { MapPinIcon, PhoneCallIcon, BellIcon } from 'assets/icons'

const header = `So, you’ve received an SMS alert from Becon, what should you do now?`

function SMSAlertPage(props) {
  return (
    <Page whiteBackground>
      <SEO doNotTrack title={'SMS Alert'} description={header}/>
      <CenterWrapper style={styles.wrapper} contentContainerStyle={styles.content}>
        <PageTitle location='Guidance' title={header}/>
        <View variant={'flex column'} style={styles.innerWrapper}>

          <Text variant={'p2 inline marginTop:4'}>
            <strong>No need to panic but please do not ignore the message.</strong>
            There are a few reasons why a Safety Trigger may have been activated, some could be minor or even accidental, however taking action as soon as possible is strongly recommended as the Becon user may need your assistance.
          </Text>

          <View style={styles.itemWrapper}>
            <PhoneCallIcon size={64} color={Theme.colors.grad1} style={{ flexShrink: 0 }}/>
            <Text variant={'p2'}>
              Firstly, we recommend that you try and contact the person as soon as possible, to find out if they’re safe. If you can’t reach them directly, then you could get in touch with other people in their social or familial circle who may be able to confirm their safety.
            </Text>
          </View>
          <View style={styles.itemWrapper}>
            <MapPinIcon size={64} color={Theme.colors.grad1} style={{ flexShrink: 0 }}/>
            <Text variant={'p2'}>
              In the SMS message you were sent, we have provided the Becon user’s precise location at the time the alert was triggered, as well as the reason for it, so this could aid in locating them and coming to their assistance, if you choose to do so.
            </Text>
          </View>
          <View style={styles.itemWrapper}>
            <BellIcon size={64} color={Theme.colors.grad1} style={{ flexShrink: 0 }}/>
            <Text variant={'p2 inline'}>
              Following your attempts to contact and locate the Becon user, you may decide to contact the emergency services. The location link you have received is in the <strong style={{ textDecoration: 'underline' }}>What3Words</strong> format, and this unique 3-word phrase corresponds to the precise 3m² location where the Safety Trigger was activated. This phrase will be understood by the emergency services and makes communicating location information simple and exact.
              <br/>
              <br/>
              If you believe that the person is missing, it may also be useful to know that, according to <strong style={{ textDecoration: 'underline' }}>Missingpeople.org.uk</strong> and <strong style={{ textDecoration: 'underline' }}>Askthepolice.org.uk</strong>, you don't have to wait 24 hours before you report them missing to the police. They say, "As soon as you can't find the person and you are worried for their safety and welfare, you can report them missing to the police."
            </Text>
          </View>
          <View>
            <View style={styles.separator}/>
            <View style={styles.buttonWrapper}>
              <Button variant={'gradient responsive noDecoration white'} text={'Learn more about how Becon works'} to={'/guidance/triggers'}/>
            </View>
          </View>
        </View>
      </CenterWrapper>
    </Page>
  )
}


const styles = {
  wrapper: {
    // minHeight: '100vh',
    ...Theme.center,
    ...Theme.flex,
    flexDirection: 'column',
    marginTop: Theme.spacing(20),
    marginBottom: Theme.spacing(4),
    [Theme.media.down('mid')]: {
      marginTop: Theme.spacing(12),
      marginBottom: Theme.spacing(3),
    },
  },
  innerWrapper: {
    gap: Theme.spacing(4),
  },
  content: {
    ...HTMLStylesDark.plainText,
    display: 'flex',
    width: 'auto',
    maxWidth: Theme.values.maxTextContentWidth,
    flexDirection: 'column',
  },
  itemWrapper: {
    display: 'flex',
    gap: Theme.spacing(4),
    [Theme.media.down('mid')]: {
      gap: Theme.spacing(2),
    },
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: Theme.colors.greyscale4,
    ...Theme.marginVertical(2),
  },
  buttonWrapper: {
    [Theme.media.down('mid')]: {
      display: 'flex',
      ...Theme.center,
    },
  },
}

export default SMSAlertPage
